import {
  EBundlesInternalViewModel,
  ESpecialOfferInternalViewModel
} from '../constants/enums';

import { mapBundleComponents } from './mapBundleComponent';
import { mapSpecialOffers } from './mapSpecialOffers';

export const renderingUtil = {
  renderBundleByInternalModel: (
    internalModel: EBundlesInternalViewModel,
    props: any
  ) => {
    const BundleComponent = mapBundleComponents[internalModel];
    return (
      <div className="bundle">
        <BundleComponent {...props} />
      </div>
    );
  },
  renderSpecialOfferByInternalModel: (
    internalModel: ESpecialOfferInternalViewModel,
    props: any
  ) => {
    const OfferComponent = mapSpecialOffers[internalModel];
    const styleCustom =
      internalModel === ESpecialOfferInternalViewModel.HUGE
        ? { width: '100%' }
        : {};

    return (
      <div className="bundle" style={styleCustom}>
        <OfferComponent {...props} />
      </div>
    );
  }
};
