import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import {
    Grid
} from '@mui/material';
import { Stack } from '@mui/system';

import useLocalization from '../../../../../api/useLocalization';
import { ENotificationType } from '../../../../../constants/enums';
import { useNotifications } from '../../../../../hooks/useNotifications';
import { AuthSliceState } from '../../../../../store/store.types';
import AcCard from '../../../../AcCard/AcCard';
import ActionButton from '../../../../ActionButton/ActionButton';
import {
    ADD_NEW_LANGUAGE_MODAL_TITLE,
    ADD_NEW_LANGUAGE_SUCCESS_MSG, DELETE_LANGUAGE_SUCCESS_MSG, EDIT_LANGUAGE_MODAL_TITLE, EDIT_LANGUAGE_SUCCESS_MSG,
    FAILED_ADD_NEW_LANGUAGE_SUCCESS_MSG,
    LANGUAGES_LIST_DESCRIPTION,
    LANGUAGES_LIST_TITLE
} from '../../constants/constants';
import { CountriesList,LanguageDataParams } from '../types';

import AddedLanguagesTable from './components/AddedLanguagesTable/AddedLanguagesTable';
import { LanguageTableRow } from './components/AddedLanguagesTable/AddedLanguagesTable.types';
import DeleteLanguageModal from './components/DeleteLanguageModal/DeleteLanguageModal';
import ManageLanguageModal from './components/ManageLanguageModal/ManageLanguageModal';

import './style.scss';

const LanguagesListSection = () => {
    const [isManageLngModalOpen, setIsManageLngModalOpen] = useState(false);
    const [isDeleteLngModalOpen, setIsDeleteLngModalOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState<LanguageTableRow | null>(null);
    const currentPublisherId = useSelector(
        ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
    );
    const { addNewLanguage, getAddedLanguagesList, editLanguage, deleteLanguage} = useLocalization(currentPublisherId);
    const { enqueueSnackbar } = useNotifications();

    const handleAddNewLanguage = () => {
        setIsManageLngModalOpen(true);
        if (selectedLanguage) {
            setSelectedLanguage(null);
        }
    }

    const handleSaveLanguageSelection = (data: LanguageDataParams[]) => {
        setIsManageLngModalOpen(false);
        setSelectedLanguage(null);
        const newLanguagesList = getAddedLanguagesList?.data ? [...getAddedLanguagesList.data, ...data] : data;

        addNewLanguage.mutate(newLanguagesList, {
            onSuccess: () => {
                getAddedLanguagesList.refetch();
                enqueueSnackbar(
                    ADD_NEW_LANGUAGE_SUCCESS_MSG,
                    ENotificationType.SUCCESS
                );
            },
            onError: (err: any) => {
                enqueueSnackbar(
                    err?.response?.data?.message ||
                    FAILED_ADD_NEW_LANGUAGE_SUCCESS_MSG,
                    ENotificationType.SUCCESS
                );
            }
        })
    }

    const handleEditLanguageSelection = (data: LanguageDataParams[]) => {
        if (!selectedLanguage) return;
        let updatedLanguagesList = getAddedLanguagesList.data.filter((data: LanguageDataParams) => data.language !== 'en');

        updatedLanguagesList = updatedLanguagesList.filter((element: LanguageDataParams) => element.language !== selectedLanguage.language);

        data.forEach((item) => {
            updatedLanguagesList.push(item)
        })

        setIsManageLngModalOpen(false);
        setSelectedLanguage(null);
        editLanguage.mutate(updatedLanguagesList, {
                onSuccess: () => {
                    getAddedLanguagesList.refetch();
                    enqueueSnackbar(
                        EDIT_LANGUAGE_SUCCESS_MSG,
                        ENotificationType.SUCCESS
                    );
                },
                onError: (data: any) => {
                    enqueueSnackbar(data.response.data.message, ENotificationType.ERROR);
                }
            },
        )
    }

    const handleLanguageDelete = () => {
        const updatedLanguagesList = getAddedLanguagesList.data.filter((item: LanguageDataParams) => item.language !== selectedLanguage?.language)

        setIsDeleteLngModalOpen(false);
        setSelectedLanguage(null);
        deleteLanguage.mutate(updatedLanguagesList, {
            onSuccess: () => {
                getAddedLanguagesList.refetch();
                enqueueSnackbar(
                    DELETE_LANGUAGE_SUCCESS_MSG,
                    ENotificationType.SUCCESS
                );
            },
            onError: (data: any) => {
                enqueueSnackbar(data.response.data.message, ENotificationType.ERROR);
            }
        },
        )

    }

    const getParsedLanguagesList = () => {
        if (!getAddedLanguagesList.data) return [];
        return getAddedLanguagesList.data.filter((item: LanguageDataParams) => item.language !== 'en').map((language: LanguageDataParams) => {
            const countryName = Object.entries(CountriesList).find(([key, value]) => value === language.country)?.[0];
            return {...language, country: countryName, countryCode: language.country}
        })
    }

    return (
        <Grid container sx={{ marginTop: '22px' }}>
            <Grid item xs={12}>
                {
                    <AcCard
                        title={LANGUAGES_LIST_TITLE}
                        description={LANGUAGES_LIST_DESCRIPTION}
                        stackContainer={false}
                        className="languagesList"
                        maxWidth={"512px"}
                    >
                        <Stack>
                            <Grid container direction="column">
                                <Grid item>
                                    <ActionButton
                                        variant="outlined"
                                        text="Add New"
                                        onClick={handleAddNewLanguage}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <AddedLanguagesTable
                                        languagesList={getParsedLanguagesList()}
                                        isLoading={getAddedLanguagesList?.isLoading}
                                        setIsDeleteLngModalOpen={setIsDeleteLngModalOpen}
                                        setSelectedLanguage={setSelectedLanguage}
                                        setIsManageLngModalOpen={setIsManageLngModalOpen}
                                    />
                                </Grid>
                            </Grid>
                        </Stack>
                    </AcCard>
                }
            </Grid>
            <ManageLanguageModal
                currentLanguage={selectedLanguage}
                isOpen={isManageLngModalOpen}
                onClose={() => {
                    setIsManageLngModalOpen(false);
                    setSelectedLanguage(null)
                }}
                title={selectedLanguage ? EDIT_LANGUAGE_MODAL_TITLE : ADD_NEW_LANGUAGE_MODAL_TITLE}
                onSave={(data: LanguageDataParams[]) => selectedLanguage ?
                        handleEditLanguageSelection(data)  : handleSaveLanguageSelection(data)
                }
            />
            <DeleteLanguageModal
                isOpen={isDeleteLngModalOpen}
                setIsDeleteModalOpen={setIsDeleteLngModalOpen}
                handleDeleteLanguage={handleLanguageDelete}
                handleSelectedLanguage={setSelectedLanguage}
            />
        </Grid>
    )
}

export default LanguagesListSection;
