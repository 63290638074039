import { Stack } from '@mui/system';

import LanguagesListSection from './components/LanguagesListSection/LanguagesListSection';
import TranslationsCSVSection from './components/TranslationsCSVSection/TranslationsCSVSection';

const LocalizationSettings = () => {
    return (
        <Stack sx={{marginTop: '-24px'}}>
            <TranslationsCSVSection />
            <LanguagesListSection />
        </Stack>
    );
}

export default LocalizationSettings;
