import React, { ChangeEvent, useEffect, useState } from 'react';

import { Stack } from '@mui/system';

import { EButtonColor } from '../../../../../../../constants/enums';
import { capitalizeFirstLetter } from '../../../../../../../utils/formattingUtil';
import AcContentWrapper from '../../../../../../AcContentWrapper/AcContentWrapper';
import AcInput from '../../../../../../AcInput/AcInput';
import AcSelect from '../../../../../../AcSelect/AcSelect';
import DialogModal from '../../../../../../Dialog/Dialog';
import { handleRenderCountriesList } from '../../../../utils';
import { CountriesList } from '../../../types';

import { ManageLanguageModalProps } from './ManageLanguageModal.types';

import '../style.scss';

const ManageLanguageModal = ({currentLanguage, isOpen, onClose, onSave, title}: ManageLanguageModalProps) => {
    const defaultCountryOption = {
        content: 'Country', key: 0, value: 'Country'
    }
    const [selectedLanguage, setSelectedLanguage] = useState<string>('');
    const [selectedCountries, setSelectedCountries] = useState<string[]>([defaultCountryOption.value]);

    const countriesOptions = Object.keys(CountriesList).length > 0 ?
        [ ...Object.entries(CountriesList).map(([key, value]) => ({ content: capitalizeFirstLetter(key.toLowerCase()), key: value, value: key }))] :
        [];

    const isControlDisabled = selectedLanguage.length === 0 || selectedCountries.length === 0 ||
        (selectedCountries.length === 1 && selectedCountries[0] === defaultCountryOption.value);

    useEffect(() => {
        setSelectedLanguage(currentLanguage?.language ?? '');
        setSelectedCountries(currentLanguage?.country ?? [defaultCountryOption.value])

    }, [currentLanguage, isOpen]);

    const handleSelectCountry = (values: string[]) => {
        let updatedSelectedCountries: string[] = [];
        values.forEach(value => {
            if (value !== defaultCountryOption.value) {
                updatedSelectedCountries.push(value)
            }
        })
        setSelectedCountries(updatedSelectedCountries)
    }

    const handleSaveLanguageData = () => {
        const parsedLanguageData = selectedCountries.map(country => ({
            language: selectedLanguage,
            country: countriesOptions.find(option => option.value === country)?.key as string ?? ''
        }));
        onSave(parsedLanguageData)
    }

    const handleModalClose = () => {
        setSelectedLanguage('');
        setSelectedCountries([defaultCountryOption.value]);
        onClose();
    }

    return (
        <DialogModal
            isOpen={isOpen}
            closeButton={true}
            headline={title}
            width={'392px'}
            withDivider={false}
            content={
                <AcContentWrapper className="addLanguageContent">
                    <Stack direction="column" sx={{width: "100%"}}>
                        <AcInput
                            header="Languages"
                            name="languages"
                            value={selectedLanguage}
                            onChange={(e: ChangeEvent<any>) => setSelectedLanguage(e.target.value)}
                            customClass="languageInput"
                            placeholder={'Language'}
                        />
                        <AcSelect
                            name="country"
                            header="Country"
                            value={selectedCountries}
                            setSelectedItems={(values: string[]) => handleSelectCountry(values)}
                            items={countriesOptions}
                            isApplyBtnEnabled={false}
                            defaultCheckedItems={[defaultCountryOption.value]}
                            renderValue={() => handleRenderCountriesList(selectedCountries)}
                            className="countrySelect"
                            isCheckBox
                        />
                    </Stack>
                </AcContentWrapper>
            }
            buttons={[
                {
                    text: 'Save',
                    color: EButtonColor.PRIMARY,
                    variant: 'contained',
                    func: handleSaveLanguageData,
                    fullWidth: true,
                    disabled: isControlDisabled
                }
            ]}
            closeDialog={handleModalClose}
        />
    )
}

export default ManageLanguageModal;
