import { Dialog } from '@mui/material';

import AcDatePicker from '../AcDatePicker/AcDatePicker';

import { DatePickerOverlayProps } from './DatePickerOverlay.types';

import './style.scss';

const DatePickerOverlay = ({
  isOpen,
  setIsOpen,
  isHourPicker = true,
  onApply,
  minDate,
  onApplyButtonText
}: DatePickerOverlayProps) => {
  return (
    <Dialog open={isOpen} maxWidth="lg">
      <AcDatePicker
        isHourPicker={isHourPicker}
        onApply={(v: any, h: any) => {
          if (!h?.[0] || !h?.[1]) {
            h = undefined;
          }
          onApply(v, h);
          setIsOpen(false);
        }}
        onClose={() => setIsOpen(false)}
        minDate={minDate}
        onApplyButtonText={onApplyButtonText}
      />
    </Dialog>
  );
};

export default DatePickerOverlay;
