import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import { Box, Grid, Stack, Typography } from '@mui/material';
import useImages from 'api/useImages';
import { AppchargeCheckout } from 'appcharge-checkout-reactjs-sdk';
import { useUploads } from 'hooks/useUpload';
import { AuthSliceState } from 'store/store.types';

import AcSolidInput from 'components/AcSolidInput/AcSolidInput';
import ActionButton from 'components/ActionButton/ActionButton';

import { EAssetType } from '../../constants/enums';
import AcCard from '../AcCard/AcCard';
import AcSelect from '../AcSelect/AcSelect';

import CheckoutThemePreview from './CheckoutThemePreview';
import { ThemeTabProps } from './theme.types';

import '../../style/forms.scss';

const CheckoutTheme = ({
  values,
  handleChange,
  handleBlur,
  touched,
  errors,
  setFieldValue
}: ThemeTabProps) => {
  const currentPublisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );
  const pictures = useImages(currentPublisherId).getImages;
  const { handleImageValue } = useUploads();
  const [showPreview, setShowPreview] = useState(false);
  const [showMobilePreview, setShowMobilePreview] = useState(false);

  return (
    <>
      {showPreview && (
        <CheckoutThemePreview
          logo={values?.checkout?.logo}
          bgImage={values?.checkout?.backgroundImage}
          primaryColor={values?.checkout?.primaryColor}
          textColor={values?.checkout?.textColor}
          buttonColor={values?.checkout?.buttonColor}
          buttonTextColor={values?.checkout?.buttonTextColor}
          onClose={() => setShowPreview(false)}
        />
      )}
      {pictures.isLoading ? null : (
        <Stack className="formContent" width="100%">
          <Stack direction="row" justifyContent="space-between">
            <Grid container>
              <Grid item xs={5}>
                <Stack gap={3}>
                  <AcCard stackContainer={false} title="Checkout Design">
                    <Grid container rowSpacing={2} columnSpacing={1.5}>
                      <Grid item xs={12}>
                        <AcSelect
                          header="Logo"
                          name="checkout.logo"
                          value={handleImageValue(
                            'checkout.logo',
                            values.checkout.logo,
                            'https://media.appcharge.com/defaults/logo.svg',
                            pictures,
                            setFieldValue
                          )}
                          uploadConfig={{
                            onUploadSuccess: async (uploadData: any) => {
                              await pictures.refetch();
                              setFieldValue(
                                'checkout.logo',
                                uploadData!.data.url
                              );
                            },
                            uploadType: EAssetType.LOGO
                          }}
                          items={[
                            {
                              content: 'Default Appcharge logo',
                              key: 'https://media.appcharge.com/defaults/logo.svg',
                              value:
                                'https://media.appcharge.com/defaults/logo.svg',
                              renderFunction: () => {
                                return (
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    gap={1}
                                  >
                                    <img
                                      src="https://media.appcharge.com/defaults/logo.svg"
                                      alt="Default Appcharge logo"
                                      style={{
                                        maxWidth: 26
                                      }}
                                    />
                                    <Typography>
                                      Default Appcharge logo
                                    </Typography>
                                  </Stack>
                                );
                              }
                            },
                            ...pictures.data
                              .filter((p: any) => p.type === EAssetType.LOGO)
                              .map((picture: any) => {
                                return {
                                  content: picture.name,
                                  key: picture.url,
                                  value: picture.url,
                                  renderFunction: () => {
                                    return (
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        gap={1}
                                      >
                                        <img
                                          src={picture.url}
                                          alt={picture.name}
                                          style={{
                                            width: 26,
                                            maxHeight: 30
                                          }}
                                        />
                                        <Typography>{picture.name}</Typography>
                                      </Stack>
                                    );
                                  }
                                };
                              })
                          ]}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.checkout?.logo &&
                            Boolean(errors.checkout?.logo)
                          }
                          tooltip="This will be the logo of your checkout."
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <AcSolidInput
                          header="Primary Color"
                          name="checkout.primaryColor"
                          defaultValue={values.checkout.primaryColor}
                          onlySolid
                          setValue={setFieldValue}
                          onBlur={handleBlur}
                          tooltip="This will be the primary color of your store. It will be visible when no background image is present."
                          error={
                            touched.checkout?.primaryColor &&
                            Boolean(errors.checkout?.primaryColor)
                          }
                          helperText={
                            touched.checkout?.primaryColor
                              ? errors.checkout?.primaryColor?.toString()
                              : ''
                          }
                        />
                        <Box mt={3}>
                          <AcSolidInput
                            header="Font Color"
                            name="checkout.textColor"
                            onlySolid
                            defaultValue={values.checkout?.textColor}
                            setValue={setFieldValue}
                            onBlur={handleBlur}
                            error={
                              touched.checkout?.textColor &&
                              Boolean(errors.checkout?.textColor)
                            }
                            helperText={
                              touched.checkout?.textColor
                                ? errors.checkout?.textColor?.toString()
                                : ''
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} mt={2}>
                        <AcSelect
                          header="Background Image"
                          name="checkout.backgroundImage"
                          value={handleImageValue(
                            'checkout.backgroundImage',
                            values.checkout?.backgroundImage,
                            'https://media.appcharge.com/defaults/background.png',
                            pictures,
                            setFieldValue
                          )}
                          onClear={() => {
                            setFieldValue('checkout.backgroundImage', '');
                          }}
                          uploadConfig={{
                            onUploadSuccess: async (uploadData: any) => {
                              await pictures.refetch();
                              setFieldValue(
                                'checkout.backgroundImage',
                                uploadData!.data.url
                              );
                            },
                            uploadType: EAssetType.BG_DESKTOP
                          }}
                          items={[
                            {
                              content: 'Default Appcharge background',
                              key: 'https://media.appcharge.com/defaults/background.png',
                              value:
                                'https://media.appcharge.com/defaults/background.png',
                              renderFunction: () => {
                                return (
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    gap={1}
                                  >
                                    <img
                                      src="https://media.appcharge.com/defaults/background.png"
                                      alt="Default Appcharge background"
                                      style={{
                                        width: 30,
                                        maxHeight: 30
                                      }}
                                    />
                                    <Typography>
                                      Default Appcharge background
                                    </Typography>
                                  </Stack>
                                );
                              }
                            },
                            ...pictures.data
                              .filter(
                                (p: any) => p.type === EAssetType.BG_DESKTOP
                              )
                              .map((picture: any) => {
                                return {
                                  content: picture.name,
                                  key: picture.url,
                                  value: picture.url,
                                  renderFunction: () => {
                                    return (
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        gap={1}
                                      >
                                        <img
                                          src={picture.url}
                                          alt={picture.name}
                                          style={{
                                            width: 30,
                                            maxHeight: 30
                                          }}
                                        />
                                        <Typography>{picture.name}</Typography>
                                      </Stack>
                                    );
                                  }
                                };
                              })
                          ]}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.general?.backgroundImageDesktop &&
                            Boolean(errors.general?.backgroundImageDesktop)
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <AcSelect
                          header="Background Image (Mobile)"
                          name="checkout.mobileBgImage"
                          value={handleImageValue(
                            'checkout.mobileBgImage',
                            values.checkout?.mobileBgImage,
                            'none',
                            pictures,
                            setFieldValue
                          )}
                          onClear={() => {
                            setFieldValue('checkout.mobileBgImage', 'none');
                          }}
                          uploadConfig={{
                            onUploadSuccess: async (uploadData: any) => {
                              await pictures.refetch();
                              setFieldValue(
                                'checkout.mobileBgImage',
                                uploadData!.data.url
                              );
                            },
                            uploadType: EAssetType.BG_MOBILE
                          }}
                          items={[
                            {
                              content: 'No Background (Taken from Desktop)',
                              key: '',
                              value: 'none',
                              renderFunction: () => {
                                return (
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    gap={1}
                                  >
                                    <Typography>
                                      No Background (Taken from Desktop)
                                    </Typography>
                                  </Stack>
                                );
                              }
                            },
                            ...pictures.data
                              .filter(
                                (p: any) => p.type === EAssetType.BG_MOBILE
                              )
                              .map((picture: any) => {
                                return {
                                  content: picture.name,
                                  key: picture.url,
                                  value: picture.url,
                                  renderFunction: () => {
                                    return (
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        gap={1}
                                      >
                                        <img
                                          src={picture.url}
                                          alt={picture.name}
                                          style={{
                                            width: 30,
                                            maxHeight: 30
                                          }}
                                        />
                                        <Typography>{picture.name}</Typography>
                                      </Stack>
                                    );
                                  }
                                };
                              })
                          ]}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.general?.backgroundImageDesktop &&
                            Boolean(errors.general?.backgroundImageDesktop)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} mt={0}>
                        <AcSolidInput
                          header="Button Color"
                          name="checkout.buttonColor"
                          onlySolid
                          defaultValue={values.checkout?.buttonColor}
                          setValue={setFieldValue}
                          onBlur={handleBlur}
                          error={
                            touched.checkout?.buttonColor &&
                            Boolean(errors.checkout?.buttonColor)
                          }
                          helperText={
                            touched.checkout?.buttonColor
                              ? errors.checkout?.buttonColor?.toString()
                              : ''
                          }
                        />
                        <Box mt={3}>
                          <AcSolidInput
                            header="Button Font Color"
                            name="checkout.buttonTextColor"
                            defaultValue={values.checkout.buttonTextColor}
                            onlySolid
                            setValue={setFieldValue}
                            onBlur={handleBlur}
                            error={
                              touched.checkout?.buttonTextColor &&
                              Boolean(errors.checkout?.buttonTextColor)
                            }
                            helperText={
                              touched.checkout?.textColor
                                ? errors.checkout?.textColor?.toString()
                                : ''
                            }
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </AcCard>
                </Stack>
                <Box mt={4}>
                  <ActionButton
                    variant="outlined"
                    size="small"
                    onClick={() => setShowPreview(true)}
                    sx={{
                      textTransform: 'capitalize'
                    }}
                    text="Preview"
                  />
                </Box>
              </Grid>
            </Grid>
            <Stack gap={1} mr={-3}>
              {/* <ActionButton
                                variant="outlined"
                                size="small"
                                onClick={() => setShowPreview(true)}
                                sx={{
                                    textTransform: 'capitalize'
                                }}
                                text={'Preview Mobile'}
                            /> */}
            </Stack>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default CheckoutTheme;
