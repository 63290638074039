import { useRef, useState } from 'react';

import { useSelector } from 'react-redux';

import { Grid, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';

import useLocalization from '../../../../../api/useLocalization';
import AcCard from '../../../../../components/AcCard/AcCard';
import { DATE_TIME_FORMAT } from '../../../../../constants/constants';
import { ENotificationType } from '../../../../../constants/enums';
import { useNotifications } from '../../../../../hooks/useNotifications';
import { AuthSliceState } from '../../../../../store/store.types';
import { errorResponse } from '../../../../../utils/errorsTextHelper';
import ActionButton from '../../../../ActionButton/ActionButton';
import ImportCSVModal from '../../../../ImportCSVModal/ImportCSVModal';
import {
    CSV_LAST_UPDATE_INFO,
    CSV_SECTION_DESCRIPTION,
    CSV_SECTION_TITLE, IMPORT_CSV_FAILURE_MESSAGE,
    IMPORT_CSV_SUCCESS_MESSAGE
} from '../../constants/constants';

import './style.scss';

const TranslationsCSVSection = () => {
    const formattedDate = dayjs().utc().format(DATE_TIME_FORMAT)
    const [isUploadCSVOpen, setUploadCSVOpen] = useState(false);
    const fileRef = useRef<HTMLInputElement>(null);
    const currentPublisherId = useSelector(
        ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
    );
    const {importTranslationsCsvFile, getTranslationsCsvFile} = useLocalization(currentPublisherId);
    const { enqueueSnackbar } = useNotifications();

    const handleImportCSV = (formData: FormData) => {
        importTranslationsCsvFile.mutate(formData, {
            onSuccess: async () => {
                enqueueSnackbar(
                    IMPORT_CSV_SUCCESS_MESSAGE,
                    ENotificationType.SUCCESS
                );
                setUploadCSVOpen(false);
            },
            onError(error) {
                enqueueSnackbar(
                    IMPORT_CSV_FAILURE_MESSAGE,
                    ENotificationType.ERROR,
                    (
                        (error as AxiosError).response?.data as {
                            message: string;
                        }
                    ).message
                );
            }
        })
    }

    const handleExportCSV = async () => {
        const response = await getTranslationsCsvFile.refetch();
        if (response.isError) {
            enqueueSnackbar(errorResponse(response.error as AxiosError), ENotificationType.ERROR);
            return
        }
            const { data } = response;
        if (data) {
            const url = window.URL.createObjectURL(new Blob([data], { type: 'text/csv' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'translations.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }
    };

    return (
        <Grid container alignItems="center">
            <Grid item xs={12}>
                <AcCard
                    title={CSV_SECTION_TITLE}
                    description={CSV_SECTION_DESCRIPTION}
                    stackContainer={false}
                    className="localizationCSVCard"
                    customStyles={{"borderBottom": "1px solid #cacbd4"}}
                    maxWidth={"512px"}
                >
                    <Stack direction="row" spacing={2}>
                        <ActionButton
                            variant="outlined"
                            text="Import"
                            onClick={() => setUploadCSVOpen(true)}
                        />
                        <ActionButton
                            variant="outlined"
                            text="Export"
                            onClick={handleExportCSV}
                        />
                    </Stack>
                    <Typography variant="body2" sx={{marginTop: '14px', fontSize: '14px'}}>{CSV_LAST_UPDATE_INFO} {formattedDate}</Typography>
                </AcCard>
                <ImportCSVModal
                    isOpen={isUploadCSVOpen}
                    onCloseModal={() => setUploadCSVOpen(false)}
                    fileRef={fileRef}
                    onImportFile={(formData: FormData) => handleImportCSV(formData)}
                    fileKeyName="languagesFile"
                />
            </Grid>
        </Grid>
    )
};

export default TranslationsCSVSection;
