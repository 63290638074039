import {
  BagelCodeSpecialOffer,
  HugeSpecialOffer,
  LocoBingoSpecialOffer,
  MatchMastersSpecialOffer,
  Me2onSpecialOffer,
  PipaSpecialOffer,
  PokerFaceSpecialOffer,
  ProductMadnessSpecialOffer
} from '@appcharge/shared-ui';

import { ESpecialOfferInternalViewModel } from '../constants/enums';

export const mapSpecialOffers: Record<ESpecialOfferInternalViewModel, any> = {
  [ESpecialOfferInternalViewModel.HUGE]: HugeSpecialOffer,
  [ESpecialOfferInternalViewModel.MATCH_MASTERS]: MatchMastersSpecialOffer,
  [ESpecialOfferInternalViewModel.POKER_FACE]: PokerFaceSpecialOffer,
  [ESpecialOfferInternalViewModel.BAGELCODE]: BagelCodeSpecialOffer,
  [ESpecialOfferInternalViewModel.LOCO_BINGO]: LocoBingoSpecialOffer,
  [ESpecialOfferInternalViewModel.ME2ON]: Me2onSpecialOffer,
  [ESpecialOfferInternalViewModel.PIPA]: PipaSpecialOffer,
  [ESpecialOfferInternalViewModel.JACKPOT_SLOTS]: ProductMadnessSpecialOffer,
  [ESpecialOfferInternalViewModel.BIG_FISH_CASINO]: ProductMadnessSpecialOffer
};

export const mapViewModelDisplayName: Record<
  ESpecialOfferInternalViewModel,
  string
> = {
  [ESpecialOfferInternalViewModel.HUGE]: 'Huge',
  [ESpecialOfferInternalViewModel.MATCH_MASTERS]: 'Match Masters',
  [ESpecialOfferInternalViewModel.POKER_FACE]: 'Poker Face',
  [ESpecialOfferInternalViewModel.BAGELCODE]: 'Bagelcode',
  [ESpecialOfferInternalViewModel.LOCO_BINGO]: 'Loco Bingo',
  [ESpecialOfferInternalViewModel.ME2ON]: 'Me2on',
  [ESpecialOfferInternalViewModel.PIPA]: 'Pipa',
  [ESpecialOfferInternalViewModel.JACKPOT_SLOTS]: 'Jackpot Slots',
  [ESpecialOfferInternalViewModel.BIG_FISH_CASINO]: 'Big Fish Casino'
};
