import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { TabContext, TabPanel } from '@mui/lab';
import { Box, CircularProgress, Tab } from '@mui/material';
import { AxiosError } from 'axios';
import { EPermissionAction, EPermissionGroup } from 'common/permissions.types';
import { IS_NO_IP_ONLY_HTTPS } from 'constants/constants';
import { useFormik } from 'formik';
import { AuthSliceState } from 'store/store.types';
import { localStorageUtil } from 'utils/localStorageUtil';
import * as yup from 'yup';

import useIntegration from '../../api/useIntegration';
import usePublisher from '../../api/usePublisher';
import useSections from '../../api/useSections';
import useTheme from '../../api/useTheme';
import {
  BannerAspectRatio,
  EBalancesTheme,
  EBundlesInternalViewModel,
  EBundlesViewModel,
  ELocalStorageKeys,
  ELogoSize,
  ENotificationType,
  ESectionViewModel
} from '../../constants/enums';
import { useNotifications } from '../../hooks/useNotifications';
import { uiActions } from '../../store/uiSlice';
import { required } from '../../utils/errorsTextHelper';
import { permissionsUtil } from '../../utils/permissionsUtil';
import AcContentWrapper from '../AcContentWrapper/AcContentWrapper';
import AcTabs from '../AcTabs/AcTabs';
import AcViewWrapper from '../AcViewWrapper/AcViewWrapper';
import PageTopBar from '../Topbar/PageTopBar';

import CheckoutTheme from './CheckoutTheme';
import LoginTheme from './LoginTheme';
import StoreTheme from './StoreTheme/StoreTheme';
import { SectionsItem } from './StoreTheme/storeTheme.types';
import SuccessTheme from './SuccessTheme';
import './style.scss';

export const defaultTheme = {
  general: {
    logo: 'https://media.appcharge.com/defaults/logo.svg',
    favicon: 'https://media.appcharge.com/defaults/logo.svg',
    tabName: 'Gaming Store',
    backgroundImageMobile:
      'https://media.appcharge.com/defaults/background.png',
    backgroundImageDesktop:
      'https://media.appcharge.com/defaults/background.png',
    storeSaleImage: '',
    footerImageMobile: '',
    footerImageDesktop: '',
    font: 'montserrat',
    fontWeightForHeaders: '',
    fontWeightForText: '',
    buttonColor: {
      gradientDirection: '',
      colorOne: '#7122ff',
      colorTwo: '',
      solid: true
    },
    buttonTextColor: 'white',
    bundlesViewModel: EBundlesViewModel.LINEAR,
    bundlesInternalViewModel: EBundlesInternalViewModel.AK,
    specialOffersViewModel: 'gallery',
    specialOffersInternalViewModel: 'hug',
    footerAppstoreLink: '',
    footerGooglePlayLink: '',
    balancesTheme: EBalancesTheme.DEFAULT,
    sectionViewModel: ESectionViewModel.DEFAULT,
    sections: [],
    popupRatio: BannerAspectRatio.SQUARE,
    productQuantityFormat: {
      milSeparator: ',',
      fractionalSeparator: '.',
      thousandShorthand: false,
      millionShorthand: false,
      billionShorthand: false
    },
    balanceQuantityFormat: {
      milSeparator: ',',
      fractionalSeparator: '.',
      thousandShorthand: false,
      millionShorthand: false,
      billionShorthand: false
    },
    specialOfferQuantityFormat: {
      milSeparator: ',',
      fractionalSeparator: '.',
      thousandShorthand: false,
      millionShorthand: false,
      billionShorthand: false
    }
  },
  login: {
    text: 'Welcome to a new members-only clubs',
    font: 'montserrat',
    textColor: 'white',
    textSize: 28,
    textWeight: 'bold',
    textOn: true,
    loginLogoImage: '',
    loginBackgroundImageMobile: '',
    loginBackgroundImageDesktop: '',
    loginLogoSize: ELogoSize.SMALL,
    otpButton: {
      backgroundColor: {
        gradientDirection: '',
        colorOne: '#7122ff',
        colorTwo: ''
      },
      text: 'OTP Text',
      icon: '',
      textColor: {
        gradientDirection: '',
        colorOne: '#7122ff',
        colorTwo: ''
      }
    }
  },
  storeScreen: {
    bundleBorderColor: {
      gradientDirection: '',
      colorOne: '#e3d3ff',
      colorTwo: '',
      solid: true
    },
    noOffersTitleText: 'No offers available',
    noOffersMessageText: 'Please check back later.',
    borderSize: 0,
    addToHomeScreen: {
      borderColor: {
        colorOne: '#000000'
      },
      backgroundColor: {
        colorOne: '#ffffff'
      }
    }
  },
  loaderScreen: {
    headerText: 'Your order is on the way',
    headerColor: {
      gradientDirection: '',
      colorOne: 'white',
      colorTwo: '',
      solid: true
    },
    headerSize: 44,
    headerWeight: 'bolder',
    headerOn: false,
    text: 'Please wait a few moments until we load your assets',
    textColor: 'white',
    textSize: 24,
    textWeight: 'bold',
    textOn: false,
    gif: ''
  },
  completedScreen: {
    headerText: 'Success',
    headerColor: {
      gradientDirection: '',
      colorOne: 'white',
      colorTwo: '',
      solid: true
    },
    headerSize: 44,
    headerWeight: 'bold',
    headerOn: true,
    text: 'Open the game to see yuor new items',
    textColor: 'white',
    textSize: 24,
    textWeight: 'bold',
    textOn: true,
    backToStoreText: 'Return to shop',
    backToGameButtonDeepLink: '',
    backToGameButtonText: 'Back to game'
  },
  integration: {
    deepLinks: {
      android: '',
      ios: '',
      web: ''
    }
  }
};

enum ESettingsState {
  STORE = 'store',
  LOGIN = 'login',
  PAYMENTS = 'payments',
  COMPLETED = 'completed',
  CHECKOUT = 'checkout'
}

const ThemeView = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const currentPublisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );

  const [disabled, setDisabled] = useState(true);
  const [tab, setValue] = useState(ESettingsState.STORE);
  const [initialValues, setInitialValue] = useState<any>({});
  const { getStoreTheme: storeTheme, updateStoreTheme } = useTheme(
    currentPublisherId || undefined
  );
  const { getPublisher, updatePublisher } = usePublisher(
    currentPublisherId || undefined
  );
  const { getIntegration, updateIntegration } =
    useIntegration(currentPublisherId);
  const { getSections, upsertSections } = useSections(currentPublisherId);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useNotifications();

  let themeSchema = yup.object().shape({
    login: yup.object().shape({
      text: yup.string().required(required('Header')),
      textColor: yup.string().required(required('Font color')),
      textSize: yup.string().required(required('Font size')),
      textWeight: yup.string().required(required('Font weight')),
      loginLogoImage: yup.string().notRequired(),
      loginBackgroundImageMobile: yup.string().notRequired(),
      loginBackgroundImageDesktop: yup.string().notRequired(),
      loginLogoSize: yup.string().required(required('Logo size')),
      otpButton: yup.object().shape({
        backgroundColor: yup.object().shape({
          colorOne: yup.string().required(required('Button main color')),
          colorTwo: yup.string()
        }),
        text: yup.string(),
        icon: yup.string(),
        textColor: yup.object().shape({
          colorOne: yup.string().required(required('Text main color')),
          colorTwo: yup.string()
        })
      })
    }),
    general: yup.object().shape({
      logo: yup.string().required(required('Logo')),
      font: yup.string().required(required('Font')),
      tabName: yup.string().required(required('Store Tab Title')),
      favicon: yup.string().required(required('Favicon')),
      backgroundImageMobile: yup
        .string()
        .required(required('Background image')),
      backgroundImageDesktop: yup
        .string()
        .required(required('Background image')),
      storeSaleImage: yup.string().notRequired(),
      footerImageMobile: yup.string().notRequired(),
      footerImageDesktop: yup.string().notRequired(),
      buttonColor: yup.object().shape({
        colorOne: yup.string().required(required('Button main color')),
        colorTwo: yup.string()
      }),
      footerAppstoreLink: yup
        .string()
        .test(
          'Must be a valid URL, starting with https://, and not an IP address',
          'Must be a valid URL, starting with https://, and not an IP address',
          (value: any) => IS_NO_IP_ONLY_HTTPS(value as string)
        )
        .notRequired(),
      footerGooglePlayLink: yup
        .string()
        .test(
          'Must be a valid URL, starting with https://, and not an IP address',
          'Must be a valid URL, starting with https://, and not an IP address',
          (value: any) => IS_NO_IP_ONLY_HTTPS(value as string)
        )
        .notRequired(),
      sectionName: yup
        .string()
        .test('is-unique', 'Section name already exists', (value) => {
          return !getSections.data?.result.some(
            (item: SectionsItem) => item.name === value
          );
        }),
      sectionId: yup
        .string()
        .test('is-unique', 'Section id already exists', (value) => {
          return !getSections.data?.result.some(
            (item: SectionsItem) => item.publisherSectionId === value
          );
        })
    }),
    loaderScreen: yup.object().shape({
      headerText: yup.string().required(required('Header')),
      headerColor: yup.object().shape({
        colorOne: yup.string().required(required('Button main color')),
        colorTwo: yup.string()
      }),
      headerSize: yup.string().required(required('Header font size')),
      headerWeight: yup.string().required(required('Header font weight')),
      text: yup.string(),
      textColor: yup.string(),
      textSize: yup.string(),
      textWeight: yup.string(),
      headerOn: yup.boolean(),
      textOn: yup.boolean()
    }),
    completedScreen: yup.object().shape({
      headerText: yup.string().required(required('Header')),
      headerColor: yup.object().shape({
        colorOne: yup.string().required(required('Button main color')),
        colorTwo: yup.string()
      }),
      headerSize: yup.string().required(required('Header font size')),
      headerWeight: yup.string().required(required('Header font weight')),
      text: yup.string(),
      textColor: yup.string(),
      textSize: yup.string(),
      textWeight: yup.string(),
      backToStoreText: yup.string().required(required('Back to store text')),
      backToGameButtonText: yup.string().required(required('Back to game text'))
    }),
    storeScreen: yup.object().shape({
      bundleBorderColor: yup.object().shape({
        colorOne: yup.string().required(required('Bundle frame main color')),
        colorTwo: yup.string()
      })
    }),
    integration: yup.object().shape({
      deepLinks: yup.object().shape({
        ios: yup
          .string()
          .matches(/.+:\/\/.*/, 'Apple deep link must be a valid url'),
        android: yup
          .string()
          .matches(/.+:\/\/.*/, 'Android deep link must be a valid url'),
        web: yup
          .string()
          .matches(/.+:\/\/.*/, 'Web deep link must be a valid url')
      })
    })
  });

  useEffect(() => {
    setInitialValue({
      general: {
        logo: storeTheme.data?.general?.logo || defaultTheme.general.logo,
        font: storeTheme.data?.general?.font || defaultTheme.general.font,
        tabName:
          getPublisher.data?.storeTabName || defaultTheme.general.tabName,
        favicon:
          storeTheme.data?.general?.favicon || defaultTheme.general.favicon,
        buttonColor: {
          colorOne:
            storeTheme.data?.general?.buttonColor.colorOne ||
            defaultTheme.general.buttonColor.colorOne,
          colorTwo:
            storeTheme.data?.general?.buttonColor.colorTwo ||
            defaultTheme.general.buttonColor.colorTwo,
          gradientDirection:
            storeTheme.data?.general?.buttonColor.gradientDirection ||
            defaultTheme.general.buttonColor.gradientDirection
        },
        backgroundImageMobile:
          storeTheme.data?.general?.backgroundImageMobile ||
          defaultTheme.general.backgroundImageMobile,
        backgroundImageDesktop:
          storeTheme.data?.general?.backgroundImageDesktop ||
          defaultTheme.general.backgroundImageDesktop,
        storeSaleImage:
          storeTheme.data?.general?.storeSaleImage ||
          defaultTheme.general.storeSaleImage,
        footerImageMobile:
          storeTheme.data?.general?.footerImageMobile ||
          defaultTheme.general.footerImageMobile,
        footerImageDesktop:
          storeTheme.data?.general?.footerImageDesktop ||
          defaultTheme.general.footerImageDesktop,
        buttonTextColor:
          storeTheme.data?.general?.buttonTextColor ||
          defaultTheme.general.buttonTextColor,
        bundlesViewModel:
          storeTheme.data?.general?.bundlesViewModel ||
          defaultTheme.general.bundlesViewModel,
        bundlesInternalViewModel:
          storeTheme.data?.general?.bundlesInternalViewModel ||
          defaultTheme.general.bundlesInternalViewModel,
        specialOffersViewModel:
          storeTheme.data?.general?.specialOffersViewModel ||
          defaultTheme.general.specialOffersViewModel,
        specialOffersInternalViewModel:
          storeTheme.data?.general?.specialOffersInternalViewModel ||
          defaultTheme.general.specialOffersInternalViewModel,
        footerAppstoreLink:
          storeTheme.data?.general?.footerAppstoreLink ||
          defaultTheme.general.footerAppstoreLink,
        footerGooglePlayLink:
          storeTheme.data?.general?.footerGooglePlayLink ||
          defaultTheme.general.footerGooglePlayLink,
        sections: getSections.data?.result || defaultTheme.general.sections,
        sectionId: ''
      },
      login: {
        text: storeTheme.data?.login?.text || defaultTheme.login.text,
        font: storeTheme.data?.login?.font || defaultTheme.login.font,
        textSize:
          storeTheme.data?.login?.textSize || defaultTheme.login.textSize,
        textColor:
          storeTheme.data?.login?.textColor || defaultTheme.login.textColor,
        textWeight:
          storeTheme.data?.login?.textWeight || defaultTheme.login.textWeight,
        loginLogoImage:
          storeTheme.data?.login?.loginLogoImage ||
          defaultTheme.login.loginLogoImage,
        loginBackgroundImageMobile:
          storeTheme.data?.login?.loginBackgroundImageMobile ||
          defaultTheme.login.loginBackgroundImageMobile,
        loginBackgroundImageDesktop:
          storeTheme.data?.login?.loginBackgroundImageDesktop ||
          defaultTheme.login.loginBackgroundImageDesktop,
        loginLogoSize: storeTheme.data?.login?.loginLogoSize || ELogoSize.SMALL,
        otpButton: {
          backgroundColor: {
            colorOne:
              storeTheme.data?.login?.otpButton?.backgroundColor?.colorOne ||
              defaultTheme.login.otpButton.backgroundColor.colorOne,
            colorTwo:
              storeTheme.data?.login?.otpButton?.backgroundColor?.colorTwo ||
              defaultTheme.login.otpButton.backgroundColor.colorTwo,
            gradientDirection:
              storeTheme.data?.login?.otpButton?.backgroundColor
                ?.gradientDirection ||
              defaultTheme.login.otpButton.backgroundColor.gradientDirection
          },
          icon:
            storeTheme.data?.login?.otpButton?.icon ||
            defaultTheme.login.otpButton.icon,
          text:
            storeTheme.data?.login?.otpButton?.text ||
            defaultTheme.login.otpButton.text,
          textColor: {
            colorOne:
              storeTheme.data?.login?.otpButton?.textColor?.colorOne ||
              defaultTheme.login.otpButton.textColor.colorOne,
            colorTwo:
              storeTheme.data?.login?.otpButton?.textColor?.colorTwo ||
              defaultTheme.login.otpButton.textColor.colorTwo,
            gradientDirection:
              storeTheme.data?.login?.otpButton?.textColor?.gradientDirection ||
              defaultTheme.login.otpButton.textColor.gradientDirection
          }
        }
      },
      loaderScreen: {
        headerText:
          storeTheme.data?.loaderScreen?.headerText ||
          defaultTheme.loaderScreen.headerText,
        headerColor: {
          colorOne:
            storeTheme.data?.loaderScreen?.headerColor.colorOne ||
            defaultTheme.loaderScreen.headerColor.colorOne,
          colorTwo:
            storeTheme.data?.loaderScreen?.headerColor.colorTwo ||
            defaultTheme.loaderScreen.headerColor.colorTwo,
          gradientDirection:
            storeTheme.data?.loaderScreen?.headerColor.gradientDirection ||
            defaultTheme.loaderScreen.headerColor.gradientDirection
        },
        headerSize:
          storeTheme.data?.loaderScreen?.headerSize ||
          defaultTheme.loaderScreen.headerSize,
        headerWeight:
          storeTheme.data?.loaderScreen?.headerWeight ||
          defaultTheme.loaderScreen.headerWeight,
        text:
          storeTheme.data?.loaderScreen?.text || defaultTheme.loaderScreen.text,
        textColor:
          storeTheme.data?.loaderScreen?.textColor ||
          defaultTheme.loaderScreen.textColor,
        textSize:
          storeTheme.data?.loaderScreen?.textSize ||
          defaultTheme.loaderScreen.textSize,
        textWeight:
          storeTheme.data?.loaderScreen?.textWeight ||
          defaultTheme.loaderScreen.textWeight,
        headerOn: defaultTheme.loaderScreen.headerOn,
        textOn: defaultTheme.loaderScreen.textOn
      },
      completedScreen: {
        headerText:
          storeTheme.data?.completedScreen?.headerText ||
          defaultTheme.completedScreen.headerText,
        headerColor: {
          colorOne:
            storeTheme.data?.completedScreen?.headerColor.colorOne ||
            defaultTheme.completedScreen.headerColor.colorOne,
          colorTwo:
            storeTheme.data?.completedScreen?.headerColor.colorTwo ||
            defaultTheme.completedScreen.headerColor.colorTwo,
          gradientDirection:
            storeTheme.data?.completedScreen?.headerColor.gradientDirection ||
            defaultTheme.completedScreen.headerColor.gradientDirection
        },
        headerSize:
          storeTheme.data?.completedScreen?.headerSize ||
          defaultTheme.completedScreen.headerSize,
        headerWeight:
          storeTheme.data?.completedScreen?.headerWeight ||
          defaultTheme.completedScreen.headerWeight,
        text:
          storeTheme.data?.completedScreen?.text ||
          defaultTheme.completedScreen.text,
        textColor:
          storeTheme.data?.completedScreen?.textColor ||
          defaultTheme.completedScreen.textColor,
        textSize:
          storeTheme.data?.completedScreen?.textSize ||
          defaultTheme.completedScreen.textSize,
        textWeight:
          storeTheme.data?.completedScreen?.textWeight ||
          defaultTheme.completedScreen.textWeight,
        backToStoreText:
          storeTheme.data?.completedScreen?.backToStoreText ||
          defaultTheme.completedScreen.backToStoreText,
        backToGameButtonText:
          storeTheme.data?.completedScreen?.backToGameButtonText ||
          defaultTheme.completedScreen.backToGameButtonText
      },
      storeScreen: {
        bundleBorderColor: {
          colorOne:
            storeTheme.data?.storeScreen?.bundleBorderColor.colorOne ||
            defaultTheme.storeScreen.bundleBorderColor.colorOne,
          colorTwo:
            storeTheme.data?.storeScreen?.bundleBorderColor.colorTwo ||
            defaultTheme.storeScreen.bundleBorderColor.colorTwo,
          gradientDirection:
            storeTheme.data?.storeScreen?.bundleBorderColor.gradientDirection ||
            defaultTheme.storeScreen.bundleBorderColor.gradientDirection
        },
        noOffersTitleText:
          storeTheme.data?.storeScreen?.noOffersTitleText ||
          defaultTheme.storeScreen,
        noOffersMessageText: storeTheme.data?.storeScreen?.noOffersMessageText,
        addToHomeScreen: {
          borderColor: {
            colorOne:
              storeTheme.data?.storeScreen?.addToHomeScreen?.borderColor?.colorOne,
            colorTwo:
              storeTheme.data?.storeScreen?.addToHomeScreen?.borderColor?.colorTwo
          },
          backgroundColor: {
            colorOne:
              storeTheme.data?.storeScreen?.addToHomeScreen?.backgroundColor?.colorOne,
            colorTwo:
              storeTheme.data?.storeScreen?.addToHomeScreen?.backgroundColor?.colorTwo
          }
        }
      },
      integration: {
        deepLinks: {
          ios:
            getIntegration.data?.deepLinks?.find(
              (platform: { platform: string; deepLink: string }) =>
                platform.platform === 'ios'
            )?.deepLink || '',
          android:
            getIntegration.data?.deepLinks?.find(
              (platform: { platform: string; deepLink: string }) =>
                platform.platform === 'android'
            )?.deepLink || '',
          web:
            getIntegration.data?.deepLinks?.find(
              (platform: { platform: string; deepLink: string }) =>
                platform.platform === 'web'
            )?.deepLink || ''
        }
      },
      checkout: {
        backgroundImage: storeTheme.data?.checkout?.backgroundImage || '',
        buttonColor: storeTheme.data?.checkout?.buttonColor,
        buttonTextColor: storeTheme.data?.checkout?.buttonTextColor || '',
        mobileBgImage: storeTheme.data?.checkout?.mobileBgImage || '',
        primaryColor: storeTheme.data?.checkout?.primaryColor || '',
        publisherId: storeTheme.data?.checkout?.publisherId || '',
        textColor: storeTheme.data?.checkout?.textColor || '',
        _id: storeTheme.data?.checkout?._id || '',
        logo: storeTheme.data?.checkout?.logo || ''
      }
    });
  }, [storeTheme.data]);

  const {
    values,
    errors,
    touched,
    dirty,
    isValid,
    isSubmitting,
    setFieldValue,
    handleChange,
    handleBlur,
    submitForm,
    setSubmitting,
    setTouched,
    setValues,
    validateField
  } = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: themeSchema,
    onSubmit: async (values) => {
      try {
        const publisherValues = {
          storeTabName: values.general.tabName
        };
        const integrationValues = {
          deepLinks: [
            {
              platform: 'ios',
              deepLink: values.integration.deepLinks.ios
            },
            {
              platform: 'android',
              deepLink: values.integration.deepLinks.android
            },
            {
              platform: 'web',
              deepLink: values.integration.deepLinks.web
            }
          ]
        };

        if (values.checkout) {
          values.checkout.publisherId =
            currentPublisherId ||
            localStorageUtil.get(ELocalStorageKeys.PUBLISHER_ID) ||
            '';
          if (
            values?.checkout?.mobileBgImage &&
            values?.checkout?.mobileBgImage === 'none'
          ) {
            delete values.checkout.mobileBgImage;
          }
        }

        const sectionsValues = values.general.sections.map(
          (value: SectionsItem) => {
            const { _id, ...rest } = value;
            return rest;
          }
        ) as SectionsItem[];

        const filteredSectionsData = sectionsValues.filter(
          (item) => !item.toBeDeleted
        );

        const newSectionsData = filteredSectionsData.map((section) => {
          const sectionData: Partial<SectionsItem> = {
            name: section.name,
            publisherSectionId: section.publisherSectionId
          };
          if (section.image) {
            sectionData.image = section.image;
          }
          if (section.sectionId) {
            sectionData.sectionId = section.sectionId;
          }
          return sectionData;
        });
        await upsertSections.mutateAsync(newSectionsData);
        await updateStoreTheme.mutateAsync(values);
        await updatePublisher.mutateAsync(publisherValues);
        await updateIntegration.mutateAsync(integrationValues);
        dispatch(uiActions.setUnsavedChanges(false));
        setSubmitting(false);

        enqueueSnackbar('Theme updated', ENotificationType.SUCCESS);

        // await storeTheme.refetch();
        await getSections.refetch();
      } catch (error) {
        enqueueSnackbar(
          'Error updating theme',
          ENotificationType.ERROR,
          (
            (error as AxiosError).response?.data as {
              message: string;
            }
          ).message || 'Unknown error occurred'
        );
        if (getSections.data?.result.length > 0) {
          await setFieldValue('general.sections', getSections.data?.result);
        }
      }
    }
  });

  useEffect(() => {
    setDisabled(!isValid || !dirty || isSubmitting);
  }, [isValid, dirty, isSubmitting]);

  useEffect(() => {
    const segments = location.pathname.split('/');
    segments[segments.length - 1] = tab;
    const newPath = segments.join('/');
    navigate(newPath);
  }, [tab]);

  const showTabs =
    !storeTheme.isLoading &&
    !getIntegration.isLoading &&
    getIntegration.data &&
    storeTheme.data;

  return (
    <>
      <AcViewWrapper
        header={
          <>
            <PageTopBar
              withTabsDesign={true}
              disable={false}
              headline="Store"
              buttons={[
                {
                  text: 'Save',
                  action: submitForm,
                  disabled: !permissionsUtil.canUserEdit() || disabled,
                  hidden:
                    permissionsUtil.isNewVersion() &&
                    !permissionsUtil.isActionEnabled(
                      null,
                      EPermissionGroup.BUILDER,
                      currentPublisherId,
                      EPermissionAction.MANAGE
                    )
                }
              ]}
            />
            <TabContext value={tab}>
              <Box pl={'3rem'} pr={'3rem'}>
                {showTabs && (
                  <AcTabs
                    value={tab}
                    onChange={(_, newValue) => setValue(newValue)}
                    aria-label="tabs navigation"
                  >
                    <Tab label="Store Settings" value={ESettingsState.STORE} />
                    <Tab label="Login Page" value={ESettingsState.LOGIN} />
                    {permissionsUtil.isSuperAdminByProject(
                      currentPublisherId || ''
                    ) && (
                      <Tab
                        label="Checkout Page"
                        value={ESettingsState.CHECKOUT}
                      />
                    )}
                    <Tab
                      label="Completed Page"
                      value={ESettingsState.COMPLETED}
                    />
                  </AcTabs>
                )}
              </Box>
            </TabContext>
          </>
        }
      >
        <AcContentWrapper>
          {!showTabs && (
            <CircularProgress
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                height: '60px',
                width: '60px'
              }}
            />
          )}
          {showTabs && (
            <TabContext value={tab}>
              <Routes>
                <Route
                  path="/store"
                  element={
                    <TabPanel value={ESettingsState.STORE}>
                      <StoreTheme
                        integration={getIntegration.data}
                        values={values}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        errors={errors}
                        touched={touched}
                        setTouched={setTouched}
                        validateField={validateField}
                      />
                    </TabPanel>
                  }
                />
                <Route
                  path="/login"
                  element={
                    <TabPanel value={ESettingsState.LOGIN}>
                      <LoginTheme
                        integration={getIntegration.data}
                        values={values}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        errors={errors}
                        touched={touched}
                        setFieldValue={setFieldValue}
                      />
                    </TabPanel>
                  }
                />
                <Route
                  path="/completed"
                  element={
                    <TabPanel value={ESettingsState.COMPLETED}>
                      <SuccessTheme
                        integration={getIntegration.data}
                        values={values}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        errors={errors}
                        touched={touched}
                        setFieldValue={setFieldValue}
                      />
                    </TabPanel>
                  }
                />
                <Route
                  path="/checkout"
                  element={
                    <TabPanel value={ESettingsState.CHECKOUT}>
                      <CheckoutTheme
                        values={values}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        errors={errors}
                        touched={touched}
                        setFieldValue={setFieldValue}
                      />
                    </TabPanel>
                  }
                />
              </Routes>
            </TabContext>
          )}
        </AcContentWrapper>
      </AcViewWrapper>
    </>
  );
};

export default ThemeView;
