
export const CSV_SECTION_TITLE = "CSV";
export const CSV_SECTION_DESCRIPTION = "Presents all strings in the system. Translated strings can be seen by scrolling horizontally to the different languages";
export const CSV_LAST_UPDATE_INFO = "Last update on";
export const LANGUAGES_LIST_TITLE = "Languages";
export const LANGUAGES_LIST_DESCRIPTION =  "Turn language toggle on in order to present your players the option to use. Don\'t forget to make sure that the language contains all relevant values in the box above."
export const ADD_NEW_LANGUAGE_MODAL_TITLE = "Add New Language"
export const EDIT_LANGUAGE_MODAL_TITLE = "Edit Language"
export const ADD_NEW_LANGUAGE_SUCCESS_MSG = "New language was added successfully"
export const FAILED_ADD_NEW_LANGUAGE_SUCCESS_MSG = 'New language was not added. Please try again'
export const DELETE_LANGUAGE_MODAL_TITLE = "Please note"
export const DELETE_LANGUAGE_MODAL_TEXT = (
    <>
        Deleting the language will delete all existing translations.<br /><br />
        Are you sure?
    </>
);
export const EDIT_LANGUAGE_SUCCESS_MSG = "Language was successfully updated"
export const DELETE_LANGUAGE_SUCCESS_MSG = "Language was successfully deleted"
export const IMPORT_CSV_SUCCESS_MESSAGE = "File has been uploaded successfully"
export const IMPORT_CSV_FAILURE_MESSAGE = "File has failed to upload"
