export interface LanguageDataParams {
    language: string;
    country: string
}

export enum CountriesList {
    AFGHANISTAN = "AF",
    ALBANIA = "AL",
    ALGERIA = "DZ",
    ANDORRA = "AD",
    ANGOLA = "AO",
    ANTIGUA_AND_BARBUDA = "AG",
    ARGENTINA = "AR",
    ARMENIA = "AM",
    AUSTRALIA = "AU",
    AUSTRIA = "AT",
    AZERBAIJAN = "AZ",
    BAHAMAS = "BS",
    BAHRAIN = "BH",
    BANGLADESH = "BD",
    BARBADOS = "BB",
    BELARUS = "BY",
    BELGIUM = "BE",
    BELIZE = "BZ",
    BENIN = "BJ",
    BHUTAN = "BT",
    BOLIVIA = "BO",
    BOSNIA_AND_HERZEGOVINA = "BA",
    BOTSWANA = "BW",
    BRAZIL = "BR",
    BRUNEI = "BN",
    BULGARIA = "BG",
    BURKINA_FASO = "BF",
    BURUNDI = "BI",
    CABO_VERDE = "CV",
    CAMBODIA = "KH",
    CAMEROON = "CM",
    CANADA = "CA",
    CENTRAL_AFRICAN_REPUBLIC = "CF",
    CHAD = "TD",
    CHILE = "CL",
    CHINA = "CN",
    COLOMBIA = "CO",
    COMOROS = "KM",
    CONGO = "CG",
    CONGO_DEMOCRATIC_REPUBLIC = "CD",
    COSTA_RICA = "CR",
    COTE_DIVOIRE = "CI",
    CROATIA = "HR",
    CUBA = "CU",
    CYPRUS = "CY",
    CZECH_REPUBLIC = "CZ",
    DENMARK = "DK",
    DJIBOUTI = "DJ",
    DOMINICA = "DM",
    DOMINICAN_REPUBLIC = "DO",
    ECUADOR = "EC",
    EGYPT = "EG",
    EL_SALVADOR = "SV",
    EQUATORIAL_GUINEA = "GQ",
    ERITREA = "ER",
    ESTONIA = "EE",
    ESWATINI = "SZ",
    ETHIOPIA = "ET",
    FIJI = "FJ",
    FINLAND = "FI",
    FRANCE = "FR",
    GABON = "GA",
    GAMBIA = "GM",
    GEORGIA = "GE",
    GERMANY = "DE",
    GHANA = "GH",
    GREECE = "GR",
    GRENADA = "GD",
    GUATEMALA = "GT",
    GUINEA = "GN",
    GUINEA_BISSAU = "GW",
    GUYANA = "GY",
    HAITI = "HT",
    HONDURAS = "HN",
    HUNGARY = "HU",
    ICELAND = "IS",
    INDIA = "IN",
    INDONESIA = "ID",
    IRAN = "IR",
    IRAQ = "IQ",
    IRELAND = "IE",
    ISRAEL = "IL",
    ITALY = "IT",
    JAMAICA = "JM",
    JAPAN = "JP",
    JORDAN = "JO",
    KAZAKHSTAN = "KZ",
    KENYA = "KE",
    KIRIBATI = "KI",
    KOREA_NORTH = "KP",
    KOREA_SOUTH = "KR",
    KUWAIT = "KW",
    KYRGYZSTAN = "KG",
    LAOS = "LA",
    LATVIA = "LV",
    LEBANON = "LB",
    LESOTHO = "LS",
    LIBERIA = "LR",
    LIBYA = "LY",
    LIECHTENSTEIN = "LI",
    LITHUANIA = "LT",
    LUXEMBOURG = "LU",
    MADAGASCAR = "MG",
    MALAWI = "MW",
    MALAYSIA = "MY",
    MALDIVES = "MV",
    MALI = "ML",
    MALTA = "MT",
    MARSHALL_ISLANDS = "MH",
    MAURITANIA = "MR",
    MAURITIUS = "MU",
    MEXICO = "MX",
    MICRONESIA = "FM",
    MOLDOVA = "MD",
    MONACO = "MC",
    MONGOLIA = "MN",
    MONTENEGRO = "ME",
    MOROCCO = "MA",
    MOZAMBIQUE = "MZ",
    MYANMAR = "MM",
    NAMIBIA = "NA",
    NAURU = "NR",
    NEPAL = "NP",
    NETHERLANDS = "NL",
    NEW_ZEALAND = "NZ",
    NICARAGUA = "NI",
    NIGER = "NE",
    NIGERIA = "NG",
    NORTH_MACEDONIA = "MK",
    NORWAY = "NO",
    OMAN = "OM",
    PAKISTAN = "PK",
    PALAU = "PW",
    PALESTINE = "PS",
    PANAMA = "PA",
    PAPUA_NEW_GUINEA = "PG",
    PARAGUAY = "PY",
    PERU = "PE",
    PHILIPPINES = "PH",
    POLAND = "PL",
    PORTUGAL = "PT",
    QATAR = "QA",
    ROMANIA = "RO",
    RUSSIA = "RU",
    RWANDA = "RW",
    SAINT_KITTS_AND_NEVIS = "KN",
    SAINT_LUCIA = "LC",
    SAINT_VINCENT_AND_THE_GRENADINES = "VC",
    SAMOA = "WS",
    SAN_MARINO = "SM",
    SAO_TOME_AND_PRINCIPE = "ST",
    SAUDI_ARABIA = "SA",
    SENEGAL = "SN",
    SERBIA = "RS",
    SEYCHELLES = "SC",
    SIERRA_LEONE = "SL",
    SINGAPORE = "SG",
    SLOVAKIA = "SK",
    SLOVENIA = "SI",
    SOLOMON_ISLANDS = "SB",
    SOMALIA = "SO",
    SOUTH_AFRICA = "ZA",
    SOUTH_SUDAN = "SS",
    SPAIN = "ES",
    SRI_LANKA = "LK",
    SUDAN = "SD",
    SURINAME = "SR",
    SWEDEN = "SE",
    SWITZERLAND = "CH",
    SYRIA = "SY",
    TAJIKISTAN = "TJ",
    TANZANIA = "TZ",
    THAILAND = "TH",
    TIMOR_LESTE = "TL",
    TOGO = "TG",
    TONGA = "TO",
    TRINIDAD_AND_TOBAGO = "TT",
    TUNISIA = "TN",
    TURKEY = "TR",
    TURKMENISTAN = "TM",
    TUVALU = "TV",
    UGANDA = "UG",
    UKRAINE = "UA",
    UNITED_ARAB_EMIRATES = "AE",
    UNITED_KINGDOM = "GB",
    UNITED_STATES = "US",
    URUGUAY = "UY",
    UZBEKISTAN = "UZ",
    VANUATU = "VU",
    VATICAN_CITY = "VA",
    VENEZUELA = "VE",
    VIETNAM = "VN",
    YEMEN = "YE",
    ZAMBIA = "ZM",
    ZIMBABWE = "ZW"
}
